<template>
  <div class="product product-box" v-observe-visibility="visibilityChanged">
    <router-link
      class="product-link"
      :to="productLink"
      data-testid="productLink"
    >
      <div
        class="product-cover"
        :class="[{ sale: labelsActive && isOnSale }, { new: labelsActive && isNew }]"
      >
        <product-image
          class="product-cover__thumb"
          :image="thumbnailObj"
          :alt="product.name | htmlDecode"
          :calc-ratio="false"
          data-testid="productImage"
        />

        <div class="box-badge-offer" v-if="product.sale_label">
          {{ product.sale_label }}
        </div>
      </div>

      <div class="product-title">
        {{ product.name | htmlDecode }}
      </div>

      <!--div class="product-subtitle">
        [Der Starke]
      </div -->

      <matrixrates :product="product" />

      <div
        v-if="product.price_incl_tax"
        class="product-price"
      >
        <span class="product-price-from">
          ab
        </span>
        <span
          class="price-original"
          v-if="product.special_price && parseFloat(product.original_price_incl_tax) > 0 && !onlyImage"
        >
          {{ product.original_price_incl_tax | price(storeView) }}
        </span>

        <span
          class="price-special"
          v-if="product.special_price && parseFloat(product.special_price) > 0 && !onlyImage"
        >
          {{ product.price_incl_tax | price(storeView) }}
        </span>

        <span
          v-if="!product.special_price && parseFloat(product.price_incl_tax) > 0 && !onlyImage"
        >
          {{ product.price_incl_tax | price(storeView) }}
        </span>
      </div>

      <div class="product-content">
        <div class="product-content-inner">
          <div class="product-title">
            {{ product.name | htmlDecode }}
          </div>

          <matrixrates :product="product" />

          <div
            v-if="product.price_incl_tax"
            class="product-price"
          >
            <span class="product-price-from">
              ab
            </span>
            <span
              class="price-original"
              v-if="product.special_price && parseFloat(product.original_price_incl_tax) > 0 && !onlyImage"
            >
              {{ product.original_price_incl_tax | price(storeView) }}
            </span>

            <span
              class="price-special"
              v-if="product.special_price && parseFloat(product.special_price) > 0 && !onlyImage"
            >
              {{ product.price_incl_tax | price(storeView) }}
            </span>

            <span
              v-if="!product.special_price && parseFloat(product.price_incl_tax) > 0 && !onlyImage"
            >
              {{ product.price_incl_tax | price(storeView) }}
            </span>
          </div>

          <div class="product-action">
            <a class="btn btn-primary">Artikel ansehen</a>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import config from 'config'
import ProductImage from './ProductImage'
// import AddToWishlist from 'theme/components/core/blocks/Wishlist/AddToWishlist'
// import AddToCompare from 'theme/components/core/blocks/Compare/AddToCompare'
// import { IsOnWishlist } from '@vue-storefront/core/modules/wishlist/components/IsOnWishlist'
// import { IsOnCompare } from '@vue-storefront/core/modules/compare/components/IsOnCompare'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

// Zaun24
import Matrixrates from 'src/modules/vsf-matrixrates/components/Matrixrates'

export default {
  mixins: [ProductTile],
  components: {
    ProductImage,
    // AddToWishlist,
    // AddToCompare
    Matrixrates
  },
  props: {
    labelsActive: {
      type: Boolean,
      default: true
    },
    onlyImage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    thumbnailObj () {
      return {
        src: this.thumbnail,
        loading: this.thumbnail
      }
    },
    favoriteIcon () {
      return this.isOnWishlist ? 'favorite' : 'favorite_border'
    },
    storeView () {
      return currentStoreView()
    }
  },
  methods: {
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (
        isVisible &&
        config.products.configurableChildrenStockPrefetchDynamic &&
        config.products.filterUnavailableVariants &&
        this.product.type_id === 'configurable' &&
        this.product.configurable_children &&
        this.product.configurable_children.length > 0
      ) {
        const skus = [this.product.sku]
        for (const confChild of this.product.configurable_children) {
          const cachedItem = rootStore.state.stock.cache[confChild.id]
          if (typeof cachedItem === 'undefined' || cachedItem === null) {
            skus.push(confChild.sku)
          }
        }
        if (skus.length > 0) {
          rootStore.dispatch('stock/list', { skus }) // store it in the cache
        }
      }
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$bg-secondary: color(secondary, $colors-background);
$border-secondary: color(secondary, $colors-border);
$color-white: color(white);

%label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $border-secondary;
  text-transform: uppercase;
  color: $color-white;
  font-size: 12px;
}

.product-image{
  &.sale {
    &::after {
      @extend %label;
      content: 'Sale';
    }
  }
  &.new {
    &::after {
      @extend %label;
      content: 'New';
    }
  }
}
</style>

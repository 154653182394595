<template>
  <div class="product-shipping">
    <span class="shipping-switcher">
      <span class="nonfree-shipping" v-if="shippingAmount > 0">
        <router-link :to="localizedRoute('/lieferung')">Versandkosten</router-link> {{ shippingAmount | price(storeView) }}
        <span class="free-from-threshold">
          <br v-if="!isFlat">

          <span
            v-if="freeShippingAmount && !hideThreshold"
          >(ab {{ freeShippingAmount | price(storeView) }} Bestellwert kostenfrei)</span>
        </span>
      </span>
      <span class="free-shipping" v-if="shippingAmount == 0">versandkostenfrei</span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isServer } from '@vue-storefront/core/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isFlat: {
      type: Boolean,
      default: false
    },
    hideThreshold: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      attributesByCode: 'attribute/getAttributeListByCode',
      getMatrixrates: 'matrixrates/getMatrixrates'
    }),
    shippingAmount () {
      const rate = this.getMatrixrates.filter(this.filterByGroup).filter((rate) => {
        return this.product.price_incl_tax >= rate.condition_from_value
      }).sort((a, b) => {
        return parseFloat(a.price) - parseFloat(b.price)
      }).find((rate) => rate !== undefined);

      if (rate) {
        return rate.price
      }
      return 0
    },
    freeShippingAmount () {
      const rate = this.getMatrixrates.filter(this.filterByGroup).find((rate) => {
        return rate.condition_from_value > 0
      })

      if (rate) {
        return Math.round(rate.condition_from_value)
      }
      return null
    },
    storeView () {
      return currentStoreView()
    }
  },
  serverPrefetch () {
    return this.fetchRates()
  },
  mounted () {
    if (!isServer) {
      this.fetchRates()
    }
  },
  methods: {
    fetchRates () {
      return this.$store.dispatch('matrixrates/loadMatrixrates', {})
    },
    getPackageId () {
      if (!this.attributesByCode.package_id) {
        return false;
      }
      return Object.values(this.attributesByCode.package_id.options).find((option) => {
        return parseInt(option.value) === parseInt(this.product.package_id)
      })
    },
    filterByGroup (rate) {
      const package_id = this.getPackageId()

      return (
        (!package_id && !rate.shipping_group) ||
        (package_id && rate.shipping_group === package_id.label)
      )
    }
  }
}
</script>

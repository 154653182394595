<template>
  <div>
    <div class="supplies-cart-message" v-if="cartMessages.length">
      <div class="modal-cart">
        <div class="h2">
          Ihr Warenkorb
        </div>
        <div class="modal-cart-content">
          <p v-for="(message, idx) in cartMessages" :key="idx">
            {{ message.message }}
          </p>
        </div>
        <div class="row">
          <div class="col-lg-6 col-lg-offset-3">
            <div class="btn-group clearfix">
              <a title="Weiter einkaufen" class="btn btn-default btn-close" @click="toggleMinified">Weiter einkaufen</a>
              <a title="zur Kasse gehen" class="btn btn-primary btn-secondary" @click="goToCart">zum Warenkorb</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="supplies" v-if="currentProduct.is_supplies_active">
      <transition name="fade">
        <!--div v-if="isEditMode" class="overlay" @click="closeEditMode" / -->
      </transition>

      <div class="supplies-inner" v-if="!isMinified">
        <div class="supplies-close">
          <button
            type="button"
            class="close"
            @click="toggleMinified"
            data-testid="closeSupplies"
          />
        </div>

        <div class="supplies-head">
          {{ $t('Supplies Configurator') }}
        </div>
        <div class="supplies-intro">
          Jetzt Zubehör für Ihren Komplettzaun finden!
        </div>

        <div class="supplies-steps">
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="{width: progressPercentage}" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" />
          </div>

          <div
            class="supplies-steps-categories"
            ref="suppliesStepsCategories"
          >
            <div
              v-for="category in categories"
              :key="category.id"
              :class="(currentCategory === category) ? 'supplies-steps-categories-step supplies-steps-categories-step__active' : 'supplies-steps-categories-step'"
              @click="scrollSuppliesStepsCategories($event)"
            >
              <a @click.prevent="setCurrentCategory(category)">{{ category.name }}</a>
            </div>
          </div>

          <div class="supplies-steps-action">
            <a class="btn btn-white" v-if="prevCategory" @click="setCurrentCategory(prevCategory); scrollSuppliesStepsCategoriesPrevNext('prev');">Zurück</a>
            <a class="btn btn-primary" v-if="nextCategory" @click="setCurrentCategory(nextCategory); scrollSuppliesStepsCategoriesPrevNext('next');">Weiter</a>
          </div>

          <!--sidebar :filters="getAvailableFilters" @changeFilter="changeFilter" /-->
        </div>

        <div class="supplies-products">
          <!-- lazy-hydrate v-if="isLazyHydrateEnabled">
            <product-listing :columns="3" :products="products" />
          </lazy-hydrate -->
          <product-listing :columns="3" :products="products" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress'
// import LazyHydrate from 'vue-lazy-hydration'
import ProductListing from 'theme/components/core/ProductListing.vue'
import { mapGetters, mapActions } from 'vuex'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

const THEME_PAGE_SIZE = 50

export default {
  components: {
    // LazyHydrate,
    ProductListing
  },
  computed: {
    ...mapGetters({
      getConfigValue: 'storeConfig/getConfigValue',
      currentCategory: 'supplies/getCurrentCategory',
      categories: 'supplies/getSuppliesCategories',
      currentProduct: 'supplies/getCurrentProduct',
      products: 'supplies/getSuppliesProducts',
      isMinified: 'supplies/isMinified',
      cartMessages: 'supplies/getCartMessages'
    }),
    isLazyHydrateEnabled () {
      return config.ssr.lazyHydrateFor.includes('category-next.products')
    },
    startCategoryId () {
      return parseInt(this.getConfigValue('supplies/general/start_category'))
    },
    nextCategory () {
      const index = this.categories.indexOf(this.currentCategory)
      if (this.categories[index + 1]) {
        return this.categories[index + 1]
      }
      return false;
    },
    prevCategory () {
      const index = this.categories.indexOf(this.currentCategory)
      if (this.categories[index - 1]) {
        return this.categories[index - 1]
      }
      return false;
    },
    progress () {
      if (!this.categories.length) {
        return 0
      }
      return (this.categories.indexOf(this.currentCategory) + 1) / this.categories.length * 100
    },
    progressPercentage () {
      return this.progress + '%'
    }
  },
  async beforeMount () {
    if (!this.startCategoryId) {
      await this.$store.dispatch('config/loadConfigData')
    }

    let rootId = 21653
    if (currentStoreView().storeCode === 'zaun24_at') {
      rootId = 21868
    }

    await this.$store.dispatch('supplies/initSuppliesCategories', rootId)
  },
  methods: {
    ...mapActions({
      setCurrentCategory: 'supplies/setCurrentCategory',
      closeSupplies: 'supplies/closeSupplies',
      toggleMinified: 'supplies/toggleMinified',
      openMicrocart: 'ui/toggleMicrocart'
    }),
    goToCart () {
      this.toggleMinified()
      this.openMicrocart()
    },
    onEscapePress () {
      this.closeSupplies()
    },
    scrollSuppliesStepsCategories (event) {
      const steps = this.$refs.suppliesStepsCategories;
      const offsetClickedStep = event.target.offsetLeft;

      steps.scrollTo({
        top: 0,
        left: (offsetClickedStep - 50),
        behavior: 'smooth'
      });
    },
    scrollSuppliesStepsCategoriesPrevNext (direction) {
      const steps = this.$refs.suppliesStepsCategories;
      let activeStep = this.$el.querySelector('.supplies-steps-categories-step__active');
      if (direction === 'prev') {
        activeStep = activeStep.previousSibling;
      } else {
        activeStep = activeStep.nextSibling;
      }
      const offsetActiveStep = activeStep.offsetLeft;

      steps.scrollTo({
        top: 0,
        left: (offsetActiveStep - 50),
        behavior: 'smooth'
      });
    }
  }
}
</script>
